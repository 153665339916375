import { api } from './main'
import refreshToken from '@/plugins/refresh-token'

const checkRegisterGet = async () => {
  await refreshToken()
  const response = await api
    .get({
      path: 'register/registerstatus',
    })
    .then(res => res.data || '0')
    .catch(err => {
      console.log('get register status error : ', err)
      return 'Register FAIL'
    })

  return response
}
export default { checkRegisterGet }
