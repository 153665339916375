<template>
  <div class='auth-wrapper auth-v2'>
    <div class='auth-inner'>
      <!-- brand logo -->
      <router-link to='/' class='brand-logo d-flex align-center'>
        <v-img
          :src='appLogo'
          max-height='30px'
          max-width='30px'
          alt='logo'
          contain
          class='me-3 d-none d-lg-block'
        ></v-img>

        <h2 class='text--primary d-none d-lg-block'>
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class='auth-row ma-0'>
        <v-col lg='8' class='d-none d-lg-block position-relative overflow-hidden pa-0'>
          <div class='auth-illustrator-wrapper h-full'>
            <!-- triangle bg -->
            <!-- tree -->

            <div class='d-flex align-center h-full pe-0'>
              <img
                height='362'
                width='100%'
                class='auth-mask-bg'
                :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              />

              <v-img
                contain
                max-width='100%'
                height='692'
                class='auth-3d-group'
                :src='require(`@/assets/images/logos/Login3.png`)'
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg='4' class='d-flex align-center auth-bg pa-3 pb-0'>
          <v-row>
            <v-col cols='12' sm='8' md='6' lg='12' class='mx-auto'>
              <v-card flat>
                <v-card-text>
                  <div align='center'>
                    <v-img contain width='200px' :src='require(`@/assets/images/logos/logo1.png`)'
                           class='mb-7'></v-img>
                  </div>
                  <p class='text-xl font-weight-semibold text--primary text-center mb-2'>
                    {{ $t('login1') }}
                    <v-avatar tile size='30' style='cursor: pointer' class='ml-2'>
                      <v-img
                        src='@/assets/images/flags/thLogin.png'
                        contain
                        alt='TH'
                        @click="
                          $i18n.locale = 'th'
                          setLang('th')
                        "
                      ></v-img>
                    </v-avatar>
                    <v-avatar tile style='cursor: pointer' size='30' class='ml-2'>
                      <v-img
                        src='@/assets/images/flags/enLogin.png'
                        contain
                        alt='TH'
                        @click="
                          $i18n.locale = 'en'
                          setLang('en')
                        "
                      ></v-img>
                    </v-avatar>
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert v-if='!isLoggedIn' text color='error'> {{ $t(alertMessage) }} !</v-alert>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref='loginForm' @submit.prevent='handleFormSubmit'>
                    <v-text-field
                      v-model='email'
                      outlined
                      label='Username'
                      placeholder='Username'
                      :rules='[validators.required, validators.usernameValidator]'
                      hide-details='auto'
                      class='mb-6'
                    ></v-text-field>
                    <v-text-field
                      v-model='password'
                      outlined
                      label='Password'
                      placeholder='Password'
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :append-icon='isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline'
                      @click:append='isPasswordVisible = !isPasswordVisible'
                      :rules='[validators.required]'
                      hide-details='auto'
                      class='mb-2'
                    ></v-text-field>
                    <v-btn block :loading='loading' :disabled='loading' color='primary'
                           type='submit' class='mt-6'>
                      {{ $t('login') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class='d-flex align-center justify-center flex-wrap mt-2'>
                  <router-link v-if='registerStatus == 1' :to="{ name: 'auth-register' }">
                    {{ $t('register_for_free') }}
                  </router-link>
                  <v-alert outlined color='success' light class='mt-5 me-2' text align='center'>
                    <div class='black--text'>
                      Powered by ©
                      <a href='https://apsth.com/' target='_bank'>APSTH</a>
                      2022, V.3.9 <br />
                      All right reserved.
                    </div>
                    <v-divider></v-divider>
                    <v-icon small color='success'> mdi-lock</v-icon>
                    {{ $t('ssl') }}
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import { useRouter } from '@core/utils'
import { required, usernameValidator } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import refreshToken from '@/plugins/refresh-token'
import genarateToken from '@/plugins/genarate-token'
import checkRegister from '@/api/checkRegister'

export default {
  setup() {
    genarateToken()
    const loginForm = ref(null)
    const publicToken = ref('')
    const { router } = useRouter()
    const alertMessage = ref('')
    const isLoggedIn = ref(true)
    const isPasswordVisible = ref(false)

    const loading = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const registerStatus = ref(null)
    const setLang = lang => {
      localStorage.setItem('locale', lang)
    }

    checkRegister.checkRegisterGet().then(res => {
      registerStatus.value = res
    })

    const handleFormSubmit = async () => {
      publicToken.value = localStorage.getItem('publicToken')
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return

      loading.value = true

      try {
        const response = await axios.post(
          'authen/login',
          { username: email.value, password: password.value, agent: 'apsth' },
          {
            headers: {
              'Access-Token': publicToken.value,
              'Authorization': `Bearer ${publicToken.value}`,
            },
          }
        )

        if (response.data.response) {
          localStorage.setItem('privateToken', response.data.token)
          const tokenStatus = await refreshToken()

          if   (tokenStatus === 'success') router.push({ name: 'dashboard' })
            router.push({ name: 'dashboard' })
            window.location.href = router.push('/')
            location.reload()
        } else {
          alertMessage.value = response.data.message
          isLoggedIn.value = false
        }
      } catch (error) {
        console.error('Oops, Unable to login!', error)
        if (error.response && error.response.data) {
          errorMessages.value = error.response.data.error
        } else {
          errorMessages.value = ['Unexpected error occurred']
        }
      } finally {
        loading.value = false
      }
    }

    return {
      handleFormSubmit,
      genarateToken,
      refreshToken,
      isPasswordVisible,
      alertMessage,
      isLoggedIn,
      publicToken,
      email,
      password,
      errorMessages,
      loading,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        usernameValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      registerStatus,

      // Template Refs
      loginForm,
      setLang,
    }
  },
}
</script>

<style lang='scss' scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
